@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700);
.centraliza-select {
    -moz-text-align-last: center;
         text-align-last: center;
    padding-right: 29px;
}

#mes_nascimento:hover, #ano_nascimento:hover{
    cursor: pointer;
}
.endereco-autocomplete{
    position: absolute;
    border-radius: 0 0 5px 5px ;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    border: 1px solid #ced4da;
    border-top: none;
    text-align: left;
}

.endereco-autocomplete li{
    cursor: pointer;
    list-style-type: none;
}

.endereco-autocomplete li a{
    color: #495057;
}

.endereco-autocomplete li a:hover{
    text-decoration: none;
}

.input_endereco_round_css{
    border-radius: 1rem 1rem 0 0 !important;
    border-bottom: none;
}

.input_endereco_round_css_recoloca_bordas{
    border-radius: 50rem !important;
}

@media only screen and (max-width: 992px) {
    .endereco-autocomplete {
        position: relative;
    }
}

@media only screen and (min-width: 992px) {
    .endereco-autocomplete {
        width: 97.3%;
    }
}
.texto-alert-danger{
    color: #721c24;
}
.btn-outline-primary {
  border: 2px solid #196CBD;
  color: #196CBD; }

.icon-tabela-creches path {
  fill: #00A6E2; }

.bg-linha-detalhe {
  background-color: #F6FBFF; }

.tr-tabela-creches:hover {
  cursor: pointer; }

.td-tabela-creches-sem-borda {
  border: none !important; }

.container-icon-tabela-creches {
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
  border-top-right-radius: 35px;
  cursor: pointer;
  width: 2em;
  height: 2em;
  display: inline-block;
  text-align: center;
  vertical-align: middle; }

.container-icon-tabela-creches:hover {
  background-color: #C2ECFC; }

.container-icon-tabela-creches svg {
  height: 1em;
  vertical-align: bottom; }

.overflow-auto .tabela-escolas {
  font-size: 0.8rem; }
  .overflow-auto .tabela-escolas tr.collapsing, .overflow-auto .tabela-escolas tr.collapse {
    background: #d7f0ff; }
  .overflow-auto .tabela-escolas .fa-chevron-down[aria-expanded="true"] {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .overflow-auto .tabela-escolas tr[aria-expanded="true"] {
    background: #d7f0ff; }

@media (min-width: 991px) {
  .tabela-escolas-div {
    max-height: 803px; } }

.mapa-completo .leaflet-container {
  height: 955px; }
  @media (min-width: 992px) {
    .mapa-completo .leaflet-container {
      width: 640px !important; } }
  @media (max-width: 991px) {
    .mapa-completo .leaflet-container {
      margin-top: 40px;
      margin-bottom: 40px; } }

.mapa-completo .mapa {
  background: #ffffff;
  border: 0 none;
  box-sizing: border-box; }
  .mapa-completo .mapa .leaflet-container {
    height: 955px; }

.mapa-completo .mapa-creche {
  background: #ffffff;
  border: 0 none;
  box-sizing: border-box; }
  .mapa-completo .mapa-creche .leaflet-container {
    height: 979px; }

.mapa-completo .mapa-vagas-remanescentes {
  background: #ffffff;
  border: 0 none;
  box-sizing: border-box; }
  .mapa-completo .mapa-vagas-remanescentes .leaflet-container {
    height: 687px; }

.mapa-completo .mapa-vagas-remanescentes-creches {
  background: #ffffff;
  border: 0 none;
  box-sizing: border-box; }
  .mapa-completo .mapa-vagas-remanescentes-creches .leaflet-container {
    height: 1051px; }

.input_selecione_categoria_round_css{
    border-radius: 1rem 1rem 0 0 !important;
    border-bottom: none;
}

div.conheca-a-rede-subtitulo {
  font-weight: bold;
  font-size: 12px;
  color: #7cd8f9; }

div.header-acessibilidade {
  height: auto;
  background-color: #363636;
  color: #fff !important;
  font-size: 12px !important;
  line-height: 20px; }
  div.header-acessibilidade li a {
    text-decoration: none;
    color: #fff; }
  div.header-acessibilidade li:hover {
    text-decoration: underline;
    cursor: pointer; }
  div.header-acessibilidade span.span-accesskey {
    margin-left: 5px;
    padding: 1px 6px;
    background-color: #196cbd; }
  div.header-acessibilidade svg {
    margin-left: 5px; }

.pref-menu {
  background-color: #9d9d9c;
  font-size: 12px;
  line-height: 12px; }
  .pref-menu li a {
    color: white; }

.links-menu {
  border-bottom: 1px solid #dee2e6; }
  @media (max-width: 576px) {
    .links-menu {
      border-bottom: 0 none !important; } }
  .links-menu .nav-link {
    border: 0 none !important; }
    .links-menu .nav-link:hover {
      border: 0 none;
      padding: 0.5rem 1rem; }

#rodape {
  background: #363636;
  color: #fff; }

.tamanho-13 {
  font-size: 13px; }

.tamanho-14 {
  font-size: 14px; }

.bd-contact {
  border-left: 1px solid #FFFFFF;
  box-sizing: border-box; }

.footer-title {
  color: #fff;
  font-size: 16px;
  font-weight: bold; }

.fonte-cor-branca, .fonte-cor-branca:hover {
  color: #fff; }

.figure-creative-commons {
  margin-top: 1.2rem; }

.fonte-maior {
  font-size: 20px !important; }
  .fonte-maior div,
  .fonte-maior ul,
  .fonte-maior table {
    font-size: 20px !important; }

.alto-contraste {
  background-color: #fefefe;
  -webkit-filter: invert(100%);
          filter: invert(100%); }
  .alto-contraste * {
    background-color: inherit; }
  .alto-contraste img:not([src*=".svg"]),
  .alto-contraste video {
    -webkit-filter: invert(100%);
            filter: invert(100%); }

body {
  font-family: "Roboto", sans-serif !important;
  letter-spacing: 0.015em; }

.fonte-12 {
  font-size: 12px; }

.fonte-14, .escolas .filtro h4, .escolas .filtro .limpar {
  font-size: 14px; }

.fonte-16 {
  font-size: 16px; }

.cor-azul {
  color: #196cbd; }

.btn-enviar-home {
  margin-top: 31px; }

.btn-success {
  color: #fff;
  background-color: #9BBA54;
  border-color: #9BBA54; }

.btn-success:hover {
  color: #fff;
  background-color: #738E34;
  border-color: #738E34; }

.busca-escolas {
  background: #f3f3f3 url(../../static/media/bg_busca.c18439db.png);
  background-size: cover;
  color: #5c6f78;
  min-height: 490px; }
  .busca-escolas .conteudo {
    margin: 80px auto;
    z-index: 1;
    width: auto; }
    .busca-escolas .conteudo h2 {
      font-size: 30px;
      font-weight: bold; }
    .busca-escolas .conteudo .texto-home {
      margin-top: 25px; }
    .busca-escolas .conteudo .busca .form-control {
      font-size: 16px;
      font-style: italic;
      text-align: center; }
    .busca-escolas .conteudo .busca .rounded-pill {
      border-radius: 30px !important; }
    .busca-escolas .conteudo .busca .rounded-bottom-0 {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important; }
    .busca-escolas .conteudo .resultados.borda-on {
      border-color: #80bdff !important; }
    .busca-escolas .conteudo .resultados.borda-off {
      border-color: #ced4da !important; }
    .busca-escolas .conteudo .resultados .cursor-link {
      cursor: pointer; }

.sociedade-governo {
  background: #196cbd; }
  .sociedade-governo button {
    background: #afce68; }

.numeros {
  background: #00a6e2; }
  .numeros .rounded-circle {
    color: #196cbd;
    height: 200px;
    text-align: center;
    vertical-align: middle;
    width: 200px; }
    .numeros .rounded-circle .valor {
      display: block;
      font-size: 40px;
      font-weight: bold; }
    .numeros .rounded-circle .texto {
      font-size: 16px; }

h2 {
  font-size: 35px;
  font-weight: bold !important;
  line-height: 40px; }
  h2.cor-azul {
    color: #196cbd; }

.menu-auxiliar {
  background: #196cbd; }
  .menu-auxiliar h3 {
    font-size: 24px;
    line-height: 28px; }

.resultados-autocomplete {
  max-height: 300px;
  overflow-y: auto;
  z-index: 5; }
  .resultados-autocomplete .cursor-padrao {
    cursor: default; }

@media (min-width: 992px) {
  .escolas {
    min-height: 860px; } }

.escolas .filtro {
  background: #00a6e2; }
  @media (max-width: 576px) {
    .escolas .filtro {
      left: 0; } }
  @media (min-width: 992px) {
    .escolas .filtro {
      position: absolute; } }
  .escolas .filtro h4 {
    font-weight: bold; }
  .escolas .filtro .limpar {
    font-weight: bold;
    line-height: 16px; }

.react-datepicker-wrapper {
  display: block !important; }
  .react-datepicker-wrapper .react-datepicker__input-container {
    display: block !important; }

.area-rodape {
  background: #5a5a5a;
  color: #ffffff;
  font-size: 0.8rem; }

.endereco {
  font-size: 0.8rem; }

.estatisticas .nav {
  border-bottom-color: #196cbd;
  font-size: 12px; }
  .estatisticas .nav .nav-link {
    color: #42474a; }
    .estatisticas .nav .nav-link.active {
      border: 0 none;
      border-bottom: 8px solid #196cbd !important;
      font-weight: bold; }
    .estatisticas .nav .nav-link:hover {
      border: 0 none; }

.estatisticas .cor-azul {
  color: #196cbd; }

.estatisticas .cor-cinza {
  color: #5c6f78; }

.estatisticas-cabecalho h1 {
  font-size: 30px; }

.estatisticas-cabecalho .referencia {
  font-size: 12px; }

