@import "../../styles/variaveis";

.mapa-completo {
  .leaflet-container {
    height: 955px;

    @media (min-width: 992px) {
      width: 640px !important;
    }
    @media (max-width: 991px) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  .mapa {
    background: $corBranco;
    border: 0 none;
    box-sizing: border-box;

    .leaflet-container {
      height: 955px;
    }
  }

  .mapa-creche {
    background: $corBranco;
    border: 0 none;
    box-sizing: border-box;

    .leaflet-container {
      height: 979px;
    }
  }

  .mapa-vagas-remanescentes {
    background: $corBranco;
    border: 0 none;
    box-sizing: border-box;

    .leaflet-container {
      height: 687px;
    }
  }

  .mapa-vagas-remanescentes-creches {
    background: $corBranco;
    border: 0 none;
    box-sizing: border-box;

    .leaflet-container {
      height: 1051px;
    }
  }
}